<template>
  <div class="home">
    <el-container class="out">
      <el-aside width="240px">
        <div
          class="logo"
          @click="toMain()"
        >
          <img src="../assets/images/ypt.png">
          <p>教育装备数据填报系统</p>
        </div>
        <div class="select">
          <i class="iconfont icon-iconfontzhizuobiaozhun0247" />
          <el-select
            v-model="projectId"
            placeholder="请选择"
            @change="changeProject"
          >
            <el-option
              v-for="item in projectList"
              :key="item.projectId"
              :label="item.projectName"
              :value="item.projectId"
            />
          </el-select>
        </div>
        <el-menu
          :unique-opened="true"
          :default-active="$route.path"
          :default-openeds="open_list"
          class="el-menu-vertical"
          router
          background-color="rgba(0,0,0,0)"
          text-color="#6B7CA7"
          active-text-color="#2A7AFF"
          @click="selectItems"
        >
          <el-menu-item
            v-if="resourceIds.indexOf('2') > -1"
            index="/all/dashboard"
            @click="closeItem"
          >
            <i class="iconfont icon-leimupinleifenleileibie" />
            <span slot="title">首页</span>
          </el-menu-item>
          <el-submenu
            v-if="resourceIds.indexOf('3') > -1"
            index="2"
          >
            <template slot="title">
              <i class="iconfont icon-icon_timesmonitoring_nor" />
              <span>应用管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                v-if="menuChildren.indexOf('151') > -1"
                index="/all/yyfz"
              >应用分组</el-menu-item>
              <el-menu-item
                v-if="menuChildren.indexOf('152') > -1"
                index="/all/yygl"
              >应用管理</el-menu-item>
              <el-menu-item
                v-if="menuChildren.indexOf('153') > -1"
                index="/all/ztgl"
              >主题管理</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <!-- <el-menu-item index="/all/report" v-if="resourceIds.indexOf('4') > -1" @click="closeItem">
            <i class="iconfont icon-baobiao"></i>
            <span slot="title">数据报表</span>
          </el-menu-item> -->
          <el-submenu
            v-if="resourceIds.indexOf('4') > -1"
            index="3"
          >
            <template slot="title">
              <i class="iconfont icon-baobiao" />
              <span>基础管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                v-if="menuChildren.indexOf('171') > -1"
                index="/all/sjygl"
              >数据源管理</el-menu-item>
              <el-menu-item
                v-if="menuChildren.indexOf('172') > -1"
                index="/all/ssrygl"
              >实施人员管理</el-menu-item>
            </el-menu-item-group>
          </el-submenu>

          <el-submenu
            v-if="resourceIds.indexOf('12') > -1"
            index="4"
          >
            <template slot="title">
              <i class="iconfont icon-tongji" />
              <span>数据分析</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                v-if="menuChildren.indexOf('154') > -1"
                index="/all/aqst"
              >安全视图</el-menu-item>
            </el-menu-item-group>
          </el-submenu>

          <el-submenu
            v-if="resourceIds.indexOf('5') > -1"
            index="5"
          >
            <template slot="title">
              <i class="iconfont icon-jilu" />
              <span>系统管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item
                v-if="menuChildren.indexOf('154') > -1"
                index="/all/xtgl"
              >系统管理</el-menu-item>
              <el-menu-item
                v-if="menuChildren.indexOf('155') > -1"
                index="/all/ywzd"
              >业务字典</el-menu-item>
              <el-menu-item
                v-if="menuChildren.indexOf('156') > -1"
                index="/all/rzgl"
              >日志管理</el-menu-item>
              <el-menu-item
                v-if="menuChildren.indexOf('181') > -1"
                index="/all/sqgl"
              >授权信息</el-menu-item>
            </el-menu-item-group>
          </el-submenu>

          <!-- <el-menu-item index="/all/equipment" v-if="resourceIds.indexOf('6') > -1" @click="closeItem">
            <i class="iconfont icon-baobiao"></i>
            <span slot="title">设备管理</span>
          </el-menu-item> -->
          <!-- <el-menu-item index="/all/user" v-if="resourceIds.indexOf('7') > -1" @click="closeItem">
            <i class="iconfont icon-yonghu"></i>
            <span slot="title">用户管理</span>
          </el-menu-item> -->
        </el-menu>
      </el-aside>
      <el-container class="main">
        <headerMain :show-title="false" />
        <!-- <el-header>
          <div class="right">
            <img src="../assets/images/person.png" />
            <p>欢迎 {{ name }}</p>
            <el-badge :value="customSysMsgUnread" v-show="customSysMsgUnread > 0" :max="99" class="item" @click="toWarn">
              <img src="../assets/images/icon/message.png" @click="toWarn" />
            </el-badge>
            <img src="../assets/images/icon/icon2.png" v-if="isOpenAdmin" @click="toAdmin" />
            <img src="../assets/images/icon/logout.png" @click="logout" />
          </div>
        </el-header> -->
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import headerMain from '../components/headerMain'
import { logout } from '@/api/user'
// @ is an alias to /src
export default {
  name: 'Home',
  components: { headerMain },
  data() {
    return {
      projectList: [], // 项目列表
      projectId: '1',
      name: '',
      customSysMsgUnread: 12,
      resourceIds: [],
      childrens: [],
      open_list: ['1'],
      openArray: [],
      isOpenAdmin: false
    }
  },
  // computed: {
  //   menuChildren() {
  //     return this.$store.state.menuChildren;
  //   },
  // },
  created() {
    this.menuChildren = localStorage.getItem('menuSon').split(',')
    this.projectId = this.$getCookie('projectId') + ''
    this.name = JSON.parse(this.$getCookie('userInfo')).userName
    // 项目列表
    this.getProjectList()
    this.resourceIds = this.$getCookie('resourceIds').split(',')
    const manageIds = ['8', '9', '10', '11', '99']
    if (this.resourceIds.length > 0) {
      const that = this
      that.isOpenAdmin =
        manageIds.filter(function(val) {
          return that.resourceIds.indexOf(val) > -1
        }).length > 0
    } else {
      this.$router.push('/404')
    }
  },
  methods: {
    // 获取项目列表
    getProjectList() {
      var res = this.$localData.searchUserProjects
      let num = 0
      res.data.forEach((item) => {
        item.projectId = item.id + ''
        num++
        item.mateId = num
      })
      this.projectList = res.data
    },
    closeItem() {
      this.open_list = ['1']
    },
    selectItems(index, path) {
    },
    toMain() {
      this.$router.push('/project')
    },
    changeProject(e) {
      document.cookie = 'projectId=' + e
      document.cookie = 'roomId='
      this.$store.commit('setRoomId', '')
      this.projectList.forEach((item) => {
        if (item.projectId === e) {
          localStorage.code = ''
          localStorage.code = JSON.parse(
            this.$getCookie('userInfo')
          ).projectTopic[item.mateId - 1].projectCode
        }
      })
      if (this.$router.currentRoute.name === 'dashboard') {
        location.reload()
      } else {
        this.$router.replace({
          name: 'dashboard'
        })
      }
    },
    toAdmin() {
      this.$router.push('/super')
    },
    toWarn() {
      this.$router.push('/warn')
    },
    logout() {
      logout().then((response) => {
        this.$message.success('登出成功')
        this.$clearAllCookie()
        localStorage.stop = true
        this.$router.push('/')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.out {
  height: 100%;
  .el-aside {
    background: linear-gradient(180deg, #131c26 0%, #152f57 66%, #0e3c83 100%);
    font-size: 14px;
    position: fixed;
    z-index: 22;
    height: 100%;
    .logo {
      display: flex;
      align-items: center;
      cursor: pointer;
      justify-content: center;
      border-bottom: 1px solid #000000;
      img {
        width: 23px;
        height: 23px;
        background: #f4f4f9;
        border-radius: 5px;
        padding: 1px;
        margin-right: 10px;
      }
      p {
        font-size: 16px;
        color: #ffffff;
        height: 78px;
        line-height: 78px;
        text-align: center;
        letter-spacing: 3px;
      }
    }
    .select {
      width: 100%;
      text-align: center;
      position: relative;
      margin: 26px 0;
      > i {
        position: absolute;
        top: 13.5px;
        left: 34px;
        color: rgba(255, 255, 255, 0.5);
        z-index: 1;
        font-size: 13px;
      }
      /deep/.el-select {
        width: 200px;
        border-radius: 5px;
        .el-input__inner {
          background: #3480ff;
          border: none;
          color: #fff;
          padding-left: 40px;
        }
      }
    }
    .el-menu {
      width: 240px;
      border-right: none;
      .el-menu-item {
        &.is-active {
          background-color: #111111 !important;
        }
        &:hover {
          background-color: #111111 !important;
        }
      }
    }
    li {
      font-size: 14px;
      padding-left: 30px;
      width: 240px;
      i {
        color: rgba(107, 124, 167, 1);
        margin-right: 10px;
        font-size: 20px;
      }
      &.is-active {
        i {
          color: rgb(42, 122, 255);
        }
      }
    }
    .el-menu-item-group {
      padding-left: 0px !important;
      background-color: #111111 !important;
      ul {
        li {
          padding-left: 49px !important;
          border-bottom: 1px solid rgb(0, 0, 0, 0.34);
          &:first-child {
            margin-top: -14px;
          }
        }
      }
    }
    li.el-submenu {
      font-size: 14px;
      padding-left: 30px;
      width: 240px;
      i {
        color: rgba(107, 124, 167, 1);
        margin-right: 10px;
        font-size: 20px;
      }
      &.is-active {
        i,
        span {
          color: rgb(42, 122, 255);
        }
      }
      &:nth-child(2) {
        padding-left: 0px !important;
        /deep/.el-submenu__title:hover,
        /deep/.el-submenu__title.is-active {
          background-color: #111111 !important;
        }
      }
      &:nth-child(3) {
        padding-left: 0px !important;
        /deep/.el-submenu__title:hover,
        /deep/.el-submenu__title.is-active {
          background-color: #111111 !important;
        }
      }
      &:nth-child(4) {
        padding-left: 0px !important;
        /deep/.el-submenu__title:hover,
        /deep/.el-submenu__title.is-active {
          background-color: #111111 !important;
        }
      }
      &:nth-child(5) {
        padding-left: 0px !important;
        /deep/.el-submenu__title:hover,
        /deep/.el-submenu__title.is-active {
          background-color: #111111 !important;
        }
        li.el-submenu {
          padding-left: 0px !important;
        }
        ul {
          li {
            padding-left: 40px !important;
          }
          li.el-menu-item-group {
            padding-left: 0px !important;
            i {
              color: rgba(107, 124, 167, 1);
              margin-right: 10px;
              font-size: 20px;
            }
            span {
              color: rgba(107, 124, 167, 1);
            }
          }
        }
        .el-menu-item-group ul li {
          padding-left: 0px;
          &.is-active {
            i,
            span {
              color: rgb(42, 122, 255);
            }
          }
          &:nth-child(4) {
            padding-left: 40px !important;
          }
          & > ul {
            li.el-menu-item {
              padding-left: 70px !important;
            }
          }
        }
      }
    }
  }
}
.main {
  overflow-y: scroll;
  background: #ececec;
  .el-header {
    box-shadow: 0px 0px 13px rgba(32, 47, 79, 0.09);
    height: 78px;
    background: #fff;
    padding: 0 25px;
    display: flex;
    align-items: center;
    justify-content: flex-end; // width: calc( 100% - 230px);
    width: 100%;
    position: fixed;
    z-index: 10;
    .right {
      display: flex;
      align-items: center;
      img {
        cursor: pointer;
        width: 37px;
        margin-right: 20px;
        &:not(:first-of-type) {
          width: 30px;
          height: 30px;
        }
      }
      .el-badge {
        margin-right: 20px;
        img {
          width: 30px;
          height: 30px;
          cursor: pointer;
          margin: 0;
        }
      }
      p {
        line-height: 27px;
        color: #445a5a;
        margin-right: 20px;
      }
    }
  }
  .el-main {
    padding: 0px;
    background: #ececec;
    overflow: initial;
    margin-top: 60px;
    margin-left: 240px;
    height: calc(100% - 60px);
    width: calc(100% - 240px);
  }
  @media screen and (max-width: 1440px) {
    .el-main {
      width: 100%;
    }
  }
}
.main::-webkit-scrollbar {
  display: none;
}

.el-submenu__title {
  color: green;
  height: 200px;
}
</style>
