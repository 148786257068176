<template>
  <el-header>
    <div
      v-if="showTitle"
      class="left"
      @click="toMain()"
    >
      <img src="../assets/images/ypt.png">
      <p>教育装备数据填报系统</p>
    </div>
    <div class="right">
      <img src="../assets/images/person.png">
      <p>欢迎 {{ name }}</p>
      <div
        v-if="totalNum>0"
        class="fixed-box"
        :style="{right:(isOpenAdmin?'145px':'85px')}"
      >
        <div
          class="tips-box"
          :class="[{'r1': shrinkPacket}, {'r2': !shrinkPacket}]"
          :max="99"
          @click="toWarn"
        >{{ totalNum>99?'99+':totalNum }}</div>
        <img
          class="rotate"
          src="../assets/images/icon/message.png"
          @click="toWarn"
        >
      </div>
      <div class="seat-box" />
      <img
        v-if="isOpenAdmin"
        src="../assets/images/icon/icon2.png"
        @click="toAdmin"
      >
      <img
        src="../assets/images/icon/logout.png"
        @click="logout"
      >
    </div>
  </el-header>
</template>

<script>
import { logout } from '@/api/user'
import tool from '@/utils/tool'
export default {
  name: 'HeaderMain',
  components: {},
  props: {
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      name: '',
      timer: 0,
      shrinkPacket: false,
      isOpenAdmin: false
    }
  },
  computed: {
    totalNum: {
      get() {
        return this.$store.state.totalNum
      },
      set(val) {
        this.$store.state.totalNum = val
      }
    },
    alarmStatus() {
      return this.$store.state.alarmStatus
    }
  },
  watch: {
    alarmStatus(v) {
      console.log(v)
      if (v) {
        this.startRotate()
      } else {
        clearInterval(this.timer)
      }
    }
  },
  mounted() {
    this.name = JSON.parse(this.$getCookie('userInfo')).userName
    this.userId = JSON.parse(this.$getCookie('userInfo')).userId
    this.$nextTick(() => {
      setTimeout(() => {
        const resourceIds = this.$getCookie('resourceIds').split(',')
        const manageIds = ['8', '9', '10', '11', '99']
        this.isOpenAdmin =
          manageIds.filter(function(val) {
            return resourceIds.indexOf(val) > -1
          }).length > 0
        this.totalNum = this.$store.state.totalNum
      }, 500)
    })
    if (this.alarmStatus) {
      this.startRotate()
    } else {
      clearInterval(this.timer)
    }
  },
  methods: {
    toAdmin() {
      this.$router.push('/super')
    },
    toMain() {
      this.$router.push('/project')
    },
    toWarn() {
      this.$router.push('/warn')
    },
    startRotate() {
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        this.shrinkPacket = !this.shrinkPacket
      }, 270)
    },
    logout() {
      const token = tool.getCookie('X-Token')
      if (token) {
        logout().then((response) => {
          this.$message.success('登出成功!')

          this.alarmStatus = false
          window.localStorage.clear()
          this.$clearAllCookie()
          this.$disconnect()
          this.$store.commit('setAlarmStatus', false)
          localStorage.stop = true
          this.$router.push('/')
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.fixed-box {
  position: fixed;
  top: 0px;
  width: 30px;
  height: 30px;
  img {
    width: 30px;
    height: 30px;
    margin-top: -4px;
  }
  .r1 {
    transform: translateY(6px);
  }
  .r2 {
    transform: translateY(8px);
  }
  .tips-box {
    display: inline-block;
    float: left;
    height: 18px;
    padding: 0 6px;
    line-height: 19px;
    font-size: 12px;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 10px;
    white-space: nowrap;
    background-color: #f56c6c;
    margin-left: 15px;
  }
}
.seat-box {
  width: 30px;
  height: 30px;
  margin: 0px 10px;
}
.el-header {
  height: 78px;
  background: #fff;
  padding: 0 25px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 10;
  .left {
    display: flex;
    cursor: pointer;
    align-items: center;
    img {
      background: #fff;
      border-radius: 5px;
      padding: 1px;
      width: 34px;
      margin-right: 12px;
    }
    p {
      font-size: 18px;
      font-weight: 500;
      color: #161820;
      opacity: 1;
    }
  }
  .right {
    display: flex;
    align-items: center;
    img {
      width: 30px;
      margin-right: 20px;
      cursor: pointer;
      &:not(:first-of-type) {
        width: 30px;
        height: 30px;
      }
    }
    p {
      line-height: 27px;
      color: #445a5a;
      margin-right: 10px;
    }
  }
}
</style>
